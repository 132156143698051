import React from 'react';

import Layout from '../components/Layout';
import SideBar from '../components/SideBar';
import Top from '../components/Top/Top';
import {Data} from '../data/data.vi';
import usFlag from '../assets/images/united-states.png';

const IndexPage = () => (
  <Layout>
    <SideBar sections={Data.sidebar} />

    <div id='main'>
      <div className='lang'>
        <a href='/en'>
          <img src={usFlag} alt='US' />
        </a>
      </div>

      <Top title={Data.top.title} content={Data.top.content} />
    </div>

  </Layout>
);

export const Head = () => {
    return (
        <>
            <meta http-equiv = "content-language" content = "vi" />
        </>
    );
};
export default IndexPage;
